import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';

import { Footer } from '../components/Footer';
import SiteNav from '../components/header/SiteNav';
import { PostFullContent } from '../components/PostContent';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteArchiveHeader,
  SiteHeader,
  SiteMain,
  SiteNavMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  //@media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  //}
`;

import config from '../website-config';
import { graphql, Link } from 'gatsby';

const Disclaimer: React.FC = props => {

  let cardImage = `${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`
  let { width, height } = props.data.header.childImageSharp.fixed;

  return (
  <IndexLayout>
    <Helmet>
      <title>{`Disclaimer - ${config.title}`}</title>
        <meta name="description" content={'Disclaimer'} />
        <meta property="og:site_name" content={config.title} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Disclaimer - ${config.title}`} />
        <meta property="og:description" content={'Disclaimer'} />
        <meta property="og:url" content={config.siteUrl + 'disclaimer'} />
        {config.facebook && <meta property="article:publisher" content={config.facebook} />}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Disclaimer - ${config.title}`} />
        <meta name="twitter:url" content={config.siteUrl + 'disclaimer'} />
        <meta property="twitter:description" content={'Disclaimer'} />
        {config.twitter && (
          <meta
            name="twitter:site"
            content={`@${config.twitter.split('https://twitter.com/')[1]}`}
          />
        )}
        <meta name="og:image" content={cardImage} />
        <meta name="twitter:image" content={cardImage} />
        <meta property="og:image:width" content={width.toString()} />
        <meta property="og:image:height" content={height.toString()} />
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
        <div css={[outer, SiteNavMain]}>
          <div css={inner}>
            <SiteNav isHome={false} isPost={true} post={{title: 'Disclaimer'}} />
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">Disclaimer</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <h5>
                Disclaimer
                </h5>

                <h6>
              GENERAL INFORMATION
                </h6>
  <br/>

                <p>
The information provided on TheEmptyRobot.com (the “Site”) is for general informational purposes only and should not be construed as advice. These are our personal opinions only.
                </p>

                <p>
The term “you” refers to anyone who uses, visits and/or views the website.
  </p>
  <p>
Please read this Disclaimer carefully, and I reserve the right to modify it at any time without notice.
  <b>
By visiting and using this website, you accept and agree to be bound by this Disclaimer along with our Terms and Conditions and Privacy Policy.
Your continued use of the website constitutes your acceptance of future changes and updates to this Disclaimer.
You must not access or use the website if you do not wish to be bound by this Disclaimer.
  </b>
  </p>




  <p>

We do not make any guarantees, representations or warranties as to, and shall have no liability for, the timeliness, truthfulness, sequence, quality, completeness, accuracy, validity or freedom from interruption of any information or data on the site.
Under no circumstances shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the Site or reliance on any information provided on the Site.
By viewing or using this data or information in any manner, you understand and acknowledge that such information is intended to provide you with a reference point for educational purposes only, rather than as a basis for making decisions.
Your use of the Site and your reliance of any information on the Site is solely at your own risk.

  </p>

  <h6>
GENERAL DISCLAIMER
  </h6>
  <br/>

  <p>
Although we strive to provide accurate general information, the information presented here is not a substitute for any kind of professional advice, and you should not rely solely on this information.
  Always consult a professional in the area for your particular needs and circumstances prior to making any professional, legal, financial or tax related decisions.

  </p>

  <h6>
NO WARRANTIES

  </h6>
  <br/>
 

  <p>
ALL CONTENT, INFORMATION, PRODUCTS AND/OR SERVICES ON THE WEBSITE ARE “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND INCLUDING THE WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PURPOSE, EXPRESS OR IMPLIED TO THE FULL EXTENT PERMISSIBLE BY LAW. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONTENT, INFORMATION, MATERIALS, PRODUCTS AND/OR SERVICES PROVIDED ON THIS WEBSITE. COMPANY MAKES NO WARRANTIES THAT THE WEBSITE WILL PERFORM OR OPERATE TO MEET YOUR REQUIREMENTS OR THAT THE INFORMATION PRESENTED HERE WILL BE COMPLETE, CURRENT OR ERROR-FREE. COMPANY DISCLAIMS ALL WARRANTIES, IMPLIED AND EXPRESS FOR ANY PURPOSE TO THE FULL EXTENT PERMITTED BY LAW.

  </p>

 

  <h6>
LIMITATION OF LIABILITY
  </h6>
  <br/>

  <p>
You agree that under no circumstances shall we be liable for any direct, indirect, incidental, consequential, equitable, special, punitive, exemplary or any other damages resulting from your use of this website including but not limited to all the content, information, products, services and graphics presented here.

  </p>
  <p>
You expressly agree that your use of the website is at your sole risk and that you are solely responsible for the accuracy of the personal and any information you provide, outcome of your actions, personal and business results, and for all other use in connection with the website.
  </p>
  <p>

You also expressly agree that we shall not be liable to you for any damages resulting from 1) any errors or omissions on the website, delay or denial of any products or services, failure of performance of any kind, interruption in the operation and your use of the website, website attacks including computer virus, hacking of information, and any other system failures; 2) any loss of income, use, data, revenue, profits, business or any goodwill related to the website; 3) any theft or unauthorized access by third party of your information from the website regardless of our negligence; and 4) any use or misuse of the information, products and/or services offered here.

  </p>
  <p>
This limitation of liability shall apply whether such liability arises from negligence, breach of contract, tort or any other legal theory of liability. You agree that we provide no express or implied guarantees to you for the content presented here, and you accept that no particular results are being promised to you here.





                </p>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);};

export default Disclaimer;

export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "img/emptyrobot-background.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
